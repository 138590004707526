import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Day = _resolveComponent("Day")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.dayOnly ? 'd-flex' : 'd-flex flex-wrap justify-content-start')
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.days, (d) => {
      return (_openBlock(), _createBlock(_component_Day, {
        key: d.day,
        onClick: ($event: any) => (_ctx.$emit('day-updated', d)),
        day: d,
        days: _ctx.days,
        dayOnly: _ctx.dayOnly,
        copiedHours: _ctx.copiedHours,
        onCopy: ($event: any) => (_ctx.$emit('copy', d))
      }, null, 8, ["onClick", "day", "days", "dayOnly", "copiedHours", "onCopy"]))
    }), 128))
  ], 2))
}