
import { Day as day } from "../../types";
import Day from "./Day.vue";
import { defineComponent, PropType } from "vue";
export default defineComponent({
  props: {
    days: Array as PropType<Array<day>>,
    dayOnly: Boolean,
    copiedHours: Object as PropType<Partial<day>>,
  },
  components: { Day },
});
