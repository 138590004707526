
import { defineComponent, PropType } from "vue";
import { Day } from "../../types";
import { POSITION, TYPE, useToast } from "vue-toastification";
export default defineComponent({
  methods: {
    pasteFromClipboard() {
      if (this.copiedHours.startTime && this.copiedHours.endTime) {
        this.localDay.active = true;
        this.localDay.startTime = this.copiedHours.startTime;
        this.localDay.endTime = this.copiedHours.endTime;
        this.$emit("day-updated", this.localDay);
      } else {
        const toast = useToast();
        toast("Cannot paste. Copy some hours first.", {
          timeout: 5000,
          type: TYPE.INFO,
          hideProgressBar: true,
          position: POSITION.TOP_LEFT,
        });
      }
    },
    dayClicked() {
      this.localDay.active = !this.localDay.active;
      this.$emit("day-updated", this.localDay);
    },
    timeUpdated() {
      this.$emit("day-updated", this.localDay);
    },
  },
  computed: {
    dayIndex() {
      return this.days.findIndex((d) => d.day === this.localDay.day);
    },
    buttonClass() {
      return this.days[this.dayIndex].active
        ? "btn btn-success m-1"
        : "btn btn-grayish m-1";
    },
  },
  props: {
    day: Object as PropType<Day>,
    dayOnly: Boolean,
    copiedHours: Object as PropType<Partial<Day>>,
    days: {
      readonly: true,
      type: Array as PropType<Array<Day>>,
      required: true,
    },
  },
  data() {
    return { localDay: this.day };
  },
});
