
import LocalService from "@/services/LocalService";
import SpSepInputContainer from "../../FormComponents/SpSepInputContainer.vue";
import { defineComponent } from "vue";
import DaysOfTheWeek from "../DaysOfTheWeek.vue";
import { Special, Day, Venue } from "../../../types";
import { POSITION, TYPE, useToast } from "vue-toastification";
import dayjs from "dayjs";
import { createDays } from "../-SearchTerms";
import CreateSpecialHeader from "./CreateSpecialHeader.vue";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ogImage from "@/assets/ogimg.png";
export default defineComponent({
  head() {
    return {
      title: this.venue?.name
        ? "Edit Specials at " + this.venue?.name
        : "Edit Special",
      meta: [
        {
          name: "description",
          content:
            "gophrr helps you find specials and plan events. View nearby deals, schedule events with friends and more. ",
        },
        {
          property: "og:description",
          content:
            "Happy hours, specials, and more on gophrr" + this.venue?.name
              ? " at " + this.venue?.name
              : "",
        },
        {
          property: "og:title",
          content:
            "Check out specials " + this.venue?.name
              ? "at " + this.venue?.name
              : "on gophrr...",
        },
        {
          property: "og:image",
          content: "https://gophrr.io/" + ogImage,
        },
        {
          property: "og:url",
          content: "https://gophrr.io",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:site_name",
          content: "gophrr",
        },
      ],
    };
  },
  async mounted() {
    await this.lookupVenuesAndSpecials();
  },
  data() {
    const twoMonthsAgo = dayjs().subtract(2, "month");
    const oneMonthAgo = dayjs().subtract(1, "month");
    const days = createDays();
    return {
      venuesWithGoodSpecials: 0,
      venuesWithSemiOldSpecials: 0,
      venuesWithOldSpecials: 0,
      venuesWithNoSpecials: 0,
      id: null,
      copiedHours: { startTime: null, endTime: null },
      specialIdToEdit: null,
      specials: [],
      googlePlaceId: "",
      name: "",
      description: "",
      type: "",
      imageUrls: [],
      price: "",
      lastVerified: null as Date | null,
      days,
      validUntil: null,
      coverCost: null,
      holiday: null,
      tags: [],
      venues: [],
      showForm: false,
      forcedTags: [],
      venue: null as Venue | null,
      twoMonthsAgo,
      forcedImageUrls: [],
      oneMonthAgo,
    };
  },
  computed: {
    deleteSpecialButtonClass() {
      return this.specialIdToEdit ? "btn btn-danger" : "btn btn-outline-danger";
    },
    validateSpecialButtonClass() {
      return this.specialIdToEdit ? "btn btn-info" : "btn btn-outline-info";
    },
    cEditingSpecialName() {
      if (Array.isArray(this.venue.specials)) {
        for (const s of this.venue.specials) {
          if (s.id === this.specialIdToEdit) {
            return s.name;
          }
        }
      }
      return "Not editing - No special selected";
    },
    cVenueName() {
      return this.venue?.name;
    },
    cVenueSpecials() {
      return this.venue.specials;
    },
  },
  methods: {
    dayjs,
    async searchVenuesAndSpecials(term: string) {
      await this.lookupVenuesAndSpecials(term);
    },
    async filterVenuesAndSpecials(type: string) {
      await this.lookupVenuesAndSpecials();
      this.venues = this.venues.filter((v) => {
        const classification = this.getSpecialClassification(v);
        return classification === type;
      });
    },
    removeFilterOnVenuesAndSpecials() {
      this.lookupVenuesAndSpecials();
    },
    copyHours(dayToCopy: Partial<Day>) {
      const toast = useToast();

      if (!dayToCopy.startTime || !dayToCopy.endTime) {
        toast("No hours to copy.", {
          timeout: 5000,
          type: TYPE.ERROR,
          position: POSITION.TOP_LEFT,
        });
      } else {
        this.copiedHours = {
          startTime: dayToCopy.startTime,
          endTime: dayToCopy.endTime,
        };
        toast("Copied hours to clipboard", {
          timeout: 5000,
          type: TYPE.INFO,
          position: POSITION.TOP_LEFT,
        });
      }
    },
    getSpecialClassification(v: Venue) {
      if (v.Specials.length === 0) return "noSpecials";
      else if (
        v.Specials.findIndex(
          (s) => this.twoMonthsAgo > dayjs(s.lastVerified)
        ) !== -1
      ) {
        return "oldSpecials";
      } else if (
        v.Specials.findIndex(
          (s) => this.oneMonthAgo > dayjs(s.lastVerified)
        ) !== -1
      ) {
        return "semiOldSpecials";
      }
      return "goodSpecials";
    },
    getVenueButtonClass(v: Venue) {
      const classification = this.getSpecialClassification(v);
      if (classification === "noSpecials") {
        return "btn btn-dark bg-dark-subtle text-dark btn-sm my-1 mx-1";
      } else if (classification === "oldSpecials") {
        return "btn btn-danger bg-danger-subtle text-dark btn-sm my-1 mx-1";
      } else if (classification === "semiOldSpecials") {
        return "btn btn-warning bg-warning-subtle text-dark btn-sm my-1 mx-1";
      }
      return "btn btn-outline-success btn-sm my-1 mx-1";
    },
    async validateSpecial() {
      const toast = useToast();
      const r = await LocalService.validateSpecial(this.specialIdToEdit);
      if (r.data.problem === false) {
        toast(r.data.msg, {
          timeout: 1200,
          type: TYPE.SUCCESS,
          hideProgressBar: true,
          position: POSITION.TOP_LEFT,
        });
      }
    },
    getSpecialClass(s: Special): string {
      if (s.specialType === "n") {
        return "btn btn-danger m-1 disabled";
      } else if (dayjs(s.lastVerified) < this.twoMonthsAgo) {
        return "btn btn-danger m-1";
      } else if (dayjs(s.lastVerified) < this.oneMonthAgo) {
        return "btn btn-warning m-1";
      }

      return s.id === this.specialIdToEdit
        ? "btn btn-primary text-light m-1"
        : "btn btn-outline-success m-1";
    },
    async deleteSpecial() {
      const toast = useToast();
      const confirmed = confirm(
        "Are you sure you want to delete " + this.name + "?"
      );
      if (confirmed) {
        const r = await LocalService.deleteSpecial(this.specialIdToEdit);
        if (r.data.problem === false) {
          this.venue.specials.splice(
            this.venue.specials.findIndex((s) => s.id === this.specialIdToEdit),
            1
          );
          toast(r.data.msg, {
            timeout: 1200,
            type: TYPE.SUCCESS,
            hideProgressBar: true,
            position: POSITION.TOP_LEFT,
          });
          this.resetSpecialDetails();
        }
      } else {
        toast("Special was NOT deleted", {
          timeout: 5000,
          type: TYPE.INFO,
          hideProgressBar: true,
          position: POSITION.TOP_LEFT,
        });
      }
    },
    resetSpecialDetails() {
      this.specialIdToEdit = null;
      this.name = "";
      this.description = "";
      this.type = "";
      this.price = "";
      this.startTime = null;
      this.endTime = null;
      this.lastVerified = null;
      this.validUntil = null;
      this.holiday = null;
      this.coverCost = null;
      this.tags = [];
      this.imageUrls = [];
      this.updateTags([]);
      this.forcedTags = [];
      this.forcedImageUrls = [];
      for (const d of this.days) {
        d.active = false;
        d.startTime = null;
        d.endTime = null;
      }
    },
    loadSpecialDetails(s: Special) {
      this.specialIdToEdit = s.id;
      this.updateTags([]);
      this.forcedTags = [];
      this.forcedImageUrls = [];
      for (const d of this.days) {
        const sd = s.SpecialDays.find((specialDay) => specialDay.day === d.day);
        // all entries from the db will be active
        d.active = sd !== undefined;
        d.startTime = sd?.startTime ?? null;
        d.endTime = sd?.endTime ?? null;
      }
      this.specialIdToEdit = s.id;
      this.name = s.name;
      this.description = s.description;
      this.type = s.type;
      this.price = s.price;
      this.startTime = s.startTime;
      this.endTime = s.endTime;
      this.lastVerified = dayjs(s.lastVerified);
      if (s.validUntil !== null) {
        this.validUntil = dayjs(s.validUntil).format("YYYY-MM-DD");
      }
      this.holiday = s.holiday;
      this.coverCost = s.coverCost;
      this.forcedTags = s.SpecialsTags.map((s) => s.tag);
      this.forcedImageUrls = s.SpecialImages.map((s) => s.imagePath);
    },
    daysOfTheWeekUpdated(d: Day) {
      const { day, startTime, endTime, active } = d;
      const index = this.days.findIndex((value) => value.day === day);
      this.days[index].active = active;
      this.days[index].startTime = startTime;
      this.days[index].endTime = endTime;
    },
    async showFormAndSetId(v: Venue, force = false) {
      const response = await LocalService.lookupVenueDetailsById(v.id);
      this.venue = response.data.venue;
      if (force) {
        this.showForm = true;
        this.id = v.id;
      } else if (!this.showForm) {
        this.showForm = true;
        this.id = v.id;
      } else if (this.id !== v.id) {
        this.id = v.id;
      } else {
        this.showForm = false;
        this.id = null;
      }
    },
    updateTags(i: string[]) {
      this.tags = JSON.parse(JSON.stringify(i));
    },
    updateImageUrls(i: string[]) {
      this.imageUrls = JSON.parse(JSON.stringify(i));
    },
    async lookupVenuesAndSpecials(term: string | undefined) {
      //   const r = await LocalService.lookupVenuesAndSpecials();
      try {
        const r = await LocalService.lookupVenueIds(term);
        this.venues = r?.data?.details;
        this.venuesWithNoSpecials = 0;
        this.venuesWithOldSpecials = 0;
        this.venuesWithSemiOldSpecials = 0;
        this.venuesWithGoodSpecials = 0;
        for (const v of this.venues) {
          const classification = this.getSpecialClassification(v);
          if (classification === "noSpecials") {
            this.venuesWithNoSpecials++;
          } else if (classification === "oldSpecials") {
            this.venuesWithOldSpecials++;
          } else if (classification === "semiOldSpecials") {
            this.venuesWithSemiOldSpecials++;
          } else {
            this.venuesWithGoodSpecials++;
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    async createSpecial() {
      if (this.price.toUpperCase() === "FREE") {
        this.price = "FREE";
      }
      if (this.price.toUpperCase() === "D") {
        this.price = "D";
      }
      const dayChecked = this.days.findIndex((d) => d.active === true);
      let toastMsg = "";
      if (this.id === null || this.id === "") {
        toastMsg += "Id is null or empty. This is a dev error.";
      }
      if (dayChecked === -1) {
        toastMsg += "\nNo days selected";
      }
      if (!this.name) {
        toastMsg += "\nMissing name field";
      }
      if (!this.description) {
        toastMsg += "\nMissing description field";
      }
      if (!this.type) {
        toastMsg += "\nMissing type field";
      }

      if (
        !this.price ||
        (Number.isNaN(parseFloat(this.price)) &&
          this.price !== "FREE" &&
          this.price !== "D")
      ) {
        toastMsg += "\nError in price field";
      }
      for (const d of this.days) {
        if (
          d.active &&
          (Number.isNaN(parseInt(d.startTime)) ||
            Number.isNaN(parseInt(d.endTime)) ||
            !d.startTime ||
            !d.endTime ||
            parseInt(d.startTime) > 2400 ||
            parseInt(d.startTime) < 0 ||
            parseInt(d.endTime) > 2400 ||
            parseInt(d.endTime) < 0)
        ) {
          console.log(d.startTime);
          toastMsg += "\nInvalid start or end time";
        }
      }

      if (this.tags.length === 0) {
        toastMsg += "\nMust include tags";
      }
      if (toastMsg !== "") {
        const toast = useToast();
        toast(toastMsg, {
          timeout: 5000,
          type: TYPE.ERROR,
          hideProgressBar: true,
          position: POSITION.TOP_LEFT,
        });
      } else {
        const r = await LocalService.createSpecial(
          this.id,
          this.days,
          this.name,
          this.description,
          this.type,
          this.price,
          this.validUntil,
          this.holiday,
          this.coverCost,
          this.tags,
          this.specialIdToEdit,
          this.imageUrls
        );

        const toast = useToast();
        const v = this.venue;
        this.resetSpecialDetails();
        toast(r.data.msg, {
          timeout: 2000,
          type: TYPE.SUCCESS,
          hideProgressBar: true,
          position: POSITION.TOP_LEFT,
        });
        this.showFormAndSetId(v, true);
        await this.lookupVenuesAndSpecials();
      }
    },
  },
  components: {
    SpSepInputContainer,
    DaysOfTheWeek,
    CreateSpecialHeader,
  },
});
