
import { debounce } from "@/common/common";
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      searchDebounce: null,
      search: "",
    };
  },
  created() {
    this.searchDebounce = debounce(this.doSearch, 1250);
  },
  methods: {
    doSearch() {
      this.$emit("search", this.search);
    },
    async debounceThenSearch() {
      this.searchDebounce();
    },
    filter(type: string) {
      this.$emit("filter", type);
    },
    reset() {
      this.search = "";
      this.$emit("reset");
    },
  },
  props: {
    venuesWithNoSpecials: Number,
    venuesWithOldSpecials: Number,
    venuesWithSemiOldSpecials: Number,
    venuesWithGoodSpecials: Number,
  },
});
