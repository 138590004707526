import { SearchTerm, Day } from "../../types";

// if making changes here, make changes to search terms on the back end
export const defaultSearchTerms: SearchTerm[] = [
  { string: "Happening Now", active: false, icon: "bi bi-watch" },
  { string: "Drinks", active: false, icon: "bi bi-cup-straw" },
  { string: "Food", active: false, icon: "bi bi-egg-fried" },
  { string: "Events", active: false, icon: "bi bi-shop" },
  { string: "Nearby", active: false, icon: "bi bi-scooter" },
];

export function createDefaultSearchTerms(): SearchTerm[] {
  return [
    { string: "Happening Now", active: false, icon: "bi bi-watch" },
    { string: "Drinks", active: false, icon: "bi bi-cup-straw" },
    { string: "Food", active: false, icon: "bi bi-egg-fried" },
    { string: "Events", active: false, icon: "bi bi-shop" },
    { string: "Nearby", active: false, icon: "bi bi-scooter" },
  ];
}

export function createDays(): Day[] {
  return [
    { day: "sunday", startTime: null, endTime: null, active: false },
    { day: "monday", startTime: null, endTime: null, active: false },
    { day: "tuesday", startTime: null, endTime: null, active: false },
    { day: "wednesday", startTime: null, endTime: null, active: false },
    { day: "thursday", startTime: null, endTime: null, active: false },
    { day: "friday", startTime: null, endTime: null, active: false },
    { day: "saturday", startTime: null, endTime: null, active: false },
  ];
}
